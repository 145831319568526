/* stylelint-disable */
@use "sass:math";

@import "variables";
@import "ant-pagination";
/* stylelint-enable */

.body {
  font-family: "Inter", sans-serif;
  color: $color-text-1;

  .ant-btn {
    height: 46px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 15px;

    &-primary {
      padding: 11px 30px 14px;
      background: $color-primary;
      border-color: $color-primary;
    }

    &-valid {
      color: $color-white;
      background: $color-green;
      border-color: $color-green;

      &:hover,
      &:focus {
        color: $color-white;
        background: lighten($color-green, 10%);
        border-color: lighten($color-green, 10%);
      }
    }
  }

  a.ant-btn.btAllFiches {
    padding-top: 7px !important;
    color: $color-blue;
    background: $color-blue-2;
    border-color: $color-blue;
  }

  a.ant-btn {
    padding-top: 6px !important;
  }

  .ant-form-item .ant-btn {
    border-radius: 8px;
  }

  .ant-tag {
    padding: 4px 30px;
    font-weight: 500;
    border: none;
    border-radius: 15px;

    &-blue {
      color: $color-blue;
      background: $color-blue-2;
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .form-consultation .ant-form-item {
    margin-bottom: 8px;
  }

  .repeater,
  .ant-collapse-header {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-collapse-header {
    display: flex;
  }

  .ant-form-item-label {
    font-size: 18px;
    font-weight: 700;

    a:not(.picto-infos) {
      position: relative;
      display: inline-block;
      color: $color-text-1;

      &::before {
        position: absolute;
        right: 0;
        bottom: 2px;
        left: 0;
        display: block;
        height: 1px;
        content: "";
        background: $color-text-1;
      }
    }
  }

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 2px;
  }

  .ant-input,
  .ant-picker,
  .ant-input-number {
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px;
  }

  .ant-input-number {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow: hidden;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-picker-input {
    flex-direction: row-reverse;
  }

  .ant-picker-clear {
    right: auto;
    left: 0;
  }

  .ant-picker-suffix {
    margin-right: 8px;
    margin-left: 0;
    color: $color-primary;
  }

  .ant-switch-checked {
    background-color: $color-green;
  }

  .ant-collapse-content-box {
    margin-left: 22px;
    border-left: 1px dashed #c1c7d0;
  }

  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding-left: 0;
  }

  .ant-upload {
    .ant-btn {
      width: 250px;
      color: $color-green;
      background: $color-bg-2;

      &:not([disabled]):hover,
      &:not([disabled]):focus {
        background: lighten($color-bg-2, 10%);
        border-color: $color-green;
      }

      &[disabled] {
        color: rgba($color-green, 0.6);
      }
    }
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    min-width: 250px;
    height: 48px;
    border-radius: 8px;
  }

  .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 20px;
    height: 20px;
    line-height: 1;

    img,
    .ant-icon {
      width: 20px;
      height: 20px;
    }
  }

  .has-file {
    .ant-upload-select-picture {
      display: none;
      // opacity: 0.6;
    }

    .ant-upload-list-item {
      margin-top: 0;
    }
  }

  .ant-upload-list-item-name {
    color: $color-text-1;
    text-decoration: underline;
  }

  .ant-input-group-compact > .ant-form-item {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $color-green;

    &::after {
      background-color: $color-green;
    }
  }

  .ant-radio-checked::after {
    border-color: $color-green;
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $color-green;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba($color-green, 8%);
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 48px;
    border-radius: 8px;

    .ant-select-selection-item {
      line-height: 48px;
    }
  }

  .form-consultation {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: auto;
        padding: 0;
        color: $color-text-1;

        .ant-select-selection-item {
          height: 22px;
          line-height: 22px;
        }
      }
    }

    .repeatableSectionContainer {
      & > div > div > div > .ant-form-item-control-input-content {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 16px;

        > :only-child {
          grid-column: 1/-1;
        }
      }
    }

    .ant-input-number-disabled {
      background: none;
    }

    .ant-upload-list-item-name {
      padding: 0;
    }

    .ant-input[disabled],
    .ant-input-number-input[disabled],
    .ant-input-number-disabled,
    .ant-picker-disabled,
    .ant-picker-input > input[disabled] {
      padding: 0;
      color: $color-text-0;
    }

    .ant-radio-disabled + span {
      color: $color-text-0;
    }

    .ant-form-item-control-input {
      min-height: 0;
    }

    .ant-input-number-input {
      height: auto;
    }

    .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
      border-color: $color-green !important;
    }

    .ant-radio-inner {
      background: $color-white;
    }

    .ant-switch-disabled {
      opacity: 1;
    }
  }

  .ant-dropdown-trigger {
    .anticon {
      margin-left: 6px;
    }
  }

  .ant-modal-header {
    padding: 0;
    text-transform: uppercase;
    border-bottom: none;
    border-radius: 15px 15px 0 0;
  }

  .ant-modal-body {
    padding: 20px 0 0;
  }

  .ant-modal-content {
    padding: 40px;
    border-radius: 15px;
  }

  .ant-popover-inner {
      border-radius: 12px;
  }
}

.ficheTable {
  &__search {
    padding: 19px 32px;
    margin-top: 20px;
    border-radius: 20px;
    box-shadow: $shadow-1;

    .ant-input-affix-wrapper {
      padding-left: 0;
      border: 0;
    }

    .ant-input {
      font-size: 16px;
      font-weight: 500;
    }

    .anticon-search {
      margin-right: 24px;
      font-size: 32px;
      color: $color-text-4;
    }

    .ant-select {
      display: block;
    }
  }

  &__filters {
    .ant-select-search__field {
      display: none;
    }

    .ant-select-multiple .ant-select-selection-item {
      font-size: 13px;
      border-radius: 12px;
    }
  }

  &__btnExport {
    color: $color-text-2 !important;
    background: $color-transparent-1 !important;
    border: 0 !important;
  }

  .ant-table {
    background: none;

    table {
      border-spacing: 0 12px;
    }
  }

  .ant-table-thead {
    > tr > th {
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: math.div(18, 14) * 1em;
      color: $color-text-4;
      background: none;
      border-bottom: 0;

      &::before {
        content: none !important;
      }
    }
  }

  &__row {
    border-radius: 15px;
    box-shadow: $shadow-1;

    > td {
      background: $color-bg-3;
      border: 0 !important;
    }

    > td:first-child {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    > td:last-child {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    &--publish {
      &:hover > td,
      > td {
        background: $color-green-light !important;
      }
    }

    &--draft,
    &--actualisation {
      &:hover > td,
      > td {
        background: $color-blue-light !important;
      }
    }

    &--refuse,
    &--incomplete,
    &--toUpdate {
      &:hover > td,
      > td {
        background: $color-red-light !important;
      }
    }

    &--pending_rcci,
    &--pending_fm,
    &--pending_dirigeant {
      &:hover > td,
      > td {
        background: $color-yellow-light !important;
      }
    }
  }

  &__type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 7px;
    font-size: 9px;
    line-height: math.div(10, 9) * 1em;
    background: #c4c4c4;
    border-radius: 5px;
  }

  &__dossier {
    font-size: 16px;
    font-weight: 600;
    color: $color-text-2;
  }

  &__client {
    color: $color-text-4;
  }

  &__date {
    font-style: italic;
    color: $color-text-4;
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    font-size: 12px;
    font-weight: 500;
    line-height: math.div(16, 12) * 1em;
    text-align: center;
    border-radius: 15px;

    &--publish {
      color: $color-green;
      background: $color-green-2;
    }

    &--draft,
    &--actualisation {
      color: $color-blue;
      background: $color-blue-2;
    }

    &--pending_rcci,
    &--pending_fm,
    &--pending_dirigeant {
      color: $color-yellow;
      background: $color-yellow-2;
    }

    &--refuse,
    &--incomplete,
    &--toUpdate {
      color: $color-red;
      background: $color-red-2;
    }
  }
}

.container {
  width: $container-w;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.page-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;

  span {
    font-weight: 600;
  }
}

img {
  max-width: 100%;
}

button {
  cursor: pointer;
}

.PanelBtBack {
  display: inline-block;
  margin-bottom: 10px;
}

// hr {
//   display: block;
//   width: 60%;
//   height: 10px !important;
//   margin: 30px auto;
//   background: linear-gradient($color-blue, $color-green);
//   border: none;
//   border-radius: 999px;
// }

.picto-infos {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 6px;
  font-size: 9px;
  font-weight: 600;
  line-height: 16px;
  color: $color-text-1;
  text-align: center;
  border: 1px solid $color-text-1;
  border-radius: 99rem;

  &:hover,
  &:focus {
    color: $color-blue;
    border-color: $color-blue;
  }
}

code {
  display: block;
  width: 100%;
  padding: 8px;
  font-size: 12px;
  background: rgb(191, 245, 212);
  border-radius: 4px;
}