@import "_variables";
@import "~include-media";

$spacing: 60px;

.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 30px 37px;
  background: $color-white;

  &_inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: $container-w;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;

    @include media(">=lg") {
      flex-wrap: nowrap;
    }
  }

  &_title {
    display: flex;
    align-items: center;
    margin: 0;
    margin-right: 60px;
    font-size: 21px;
    font-weight: 800;
  }

  &_gotoHome {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 50px;
    height: 50px;
    // margin-left: 70px;
    color: #262626;

    :global .anticon {
      margin-right: 8px;
    }

    @include media("<lg") {
      margin-left: 0;
    }
  }

  @include media(">=lg") {
    position: fixed;
  }
}

.menu {
  display: flex;
  margin-right: auto;

  a {
    flex-shrink: 0;
    margin: 0 10px;
  }

  @include media(">=lg") {
    margin-left: 40px;

    a {
      margin: 0 20px;
    }
  }
}

.submenu {
  background: white;
  border-radius: 15px;
  box-shadow: $shadow-1, $shadow-3, $shadow-1;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 14px;

  @include media(">=lg") {
    padding-top: $header-h;
  }
}

.main {
  flex-grow: 1;
}

.logout {
  padding: 0;
  font-weight: 400 !important;
  border: none;
  box-shadow: none;

  span {
    text-decoration: underline;
  }
}

.user {
  font-weight: 600;

  @include media("<lg") {
    display: none;
  }
}

.footer {
  margin-top: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: $color-text-4;
  text-align: center;
}
