.body {
  .ant-pagination {
    padding: 14px 26px;
    background: $color-white;
    border-radius: 15px;
    box-shadow: $shadow-1, $shadow-3;
  }

  .ant-pagination-total-text {
    margin-right: auto;
    font-size: 16px;
    color: $color-text-4;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    min-width: 36px;

    .ant-pagination-item-link {
      font-size: 16px;
      color: $color-text-4;
      background: $color-bg-2;
      border: 0;
      border-radius: 10px;
    }

    &:not(.ant-pagination-disabled) {
      .ant-pagination-item-link:hover {
        color: $color-white;
        background: $color-blue;
      }
    }
  }

  .ant-pagination-disabled {
    opacity: 0.4;
  }

  .ant-pagination-item {
    border: 0;

    a {
      font-size: 16px;
      color: $color-text-4;
    }
  }

  .ant-pagination-item-active {
    a {
      color: $color-blue;
    }
  }

  .ant-pagination-options {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 0;
    }
  }
}
